// import menuImage from '../assets/image/menu-image.webp'
import img from '~assets/image/menu/MultiTouch-40px.svg';
import img2 from '~assets/image/menu/FreeInmails-40px.svg';
import img3 from '~assets/image/menu/send-voice-notes.svg';
import img4 from '~assets/image/menu/automated-greetings.svg';
import img5 from '~assets/image/menu/SyncData-40px.svg';
import img6 from '~assets/image/menu/PowerfullCampaign-40px.svg';
import img7 from '~assets/image/menu/TeamManagement-40px.svg';
import img8 from '~assets/image/menu/ClientManage-40px.svg';
import img9 from '~assets/image/menu/Reporting-40px.svg';
import img10 from '~assets/image/menu/AI-Writer.svg';


export const menuItems = [
         
         
         {
           name: "megamenu",
           label: "Product",
           home: "/",
           title: "",
           megamenu:true,
           col:12,
           items: {
            //  image:{
            //   url:menuImage,
            //   col:"5",
            //  },
             col_1 : {
              title:"Solutions",
              
             },
             col_2 : {
              title:"Platform",
              
             },
             
           }
         },
         {
          name: "pricing",
          label: "Pricing",
          
        },
        {
          name: "customer-stories",
          label: "Success Stories",
          
        },
        {
          name: "",
          label: "Partners",
          items: [
            { name: "agency", label: "Agency" },
            { name: "affiliates", label: "Affiliates" },
            { name: "whitelabel", label: "Whitelabel" },
            
            
          ],
        },
        {
          name: "",
          label: "Resources",
          items: [
            { name: "//we-connect.io/blog", label: "Blog", elink: true },
            { name: "//www.youtube.com/@we-connect", label: "Videos", elink: true },
            { name: "pricing#faq", label: "FAQ" },
            { name: "free-email-signature-generator", label: "Email Signature" },
            { name: "//support.we-connect.io", label: "Help Center", elink: true },
            { name: "integrations", label: "Integrations" },
          ],
          
        },
        {
          name: "//account.we-connect.io",
          label: "Login",
          isExternal: true,
        },
        {
          name: "//account.we-connect.io/signup",
          label: "Try 14 day trial",
          isExternal: true,
        },
         

       ];
export default menuItems;