import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
    <Helmet>
          <title>We-Connect | The Safest Linkedin Automation Software</title>
          <link rel="icon" type="image/png" href="https://we-connect.io/favicon.png" />
          <link rel="apple-touch-icon-precomposed" type="image/png" href="https://we-connect.io/apple-icon-57x57.png"/>
          <link rel="apple-touch-icon-precomposed" type="image/png" href="https://we-connect.io/apple-icon-72x72.png" sizes="72x72"/>
          <link rel="apple-touch-icon-precomposed" type="image/png" href="https://we-connect.io/apple-icon-114x114.png" sizes="114x114"/>
          <meta name="description" content="Elevate your lead generation game using We-Connect, the safest cloud-based LinkedIn outreach platform that helps you easily connect with more prospects through automation, personalization, and analytics. Try our 14-days free trial."/>
          <meta property="og:title" content="We-Connect | The most advanced and affordable LinkedIn outreach platform."/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://we-connect.io"/>
          <meta property="og:image" content="https://we-connect.io/we-connect_home-page.png"/>
          <meta property="og:description" content="Elevate your lead generation game using We-Connect, the safest cloud-based LinkedIn outreach platform that helps you easily connect with more prospects through automation, personalization, and analytics. Try our 14-days free trial."/>
          <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
          <meta property="twitter:title" content="We-Connect | The most advanced and affordable LinkedIn outreach platform."/>
          <meta property="twitter:image" content="https://we-connect.io/we-connect_home-page.png"/>
          <meta property="twitter:description" content="Elevate your lead generation game using We-Connect, the safest cloud-based LinkedIn outreach platform that helps you easily connect with more prospects through automation, personalization, and analytics. Try our 14-days free trial."/>
    </Helmet>
      <Header/>
      {children}
    </>
  )
}